import { Link } from "gatsby";
import Img from "gatsby-image";

import styled, { css } from "../../../theme/styled";

import * as Typo from "../../typo";

// MARK: Case studies section

export const CaseStudiesSection = styled.section`
  margin-top: 32px;
  margin-bottom: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    margin-top: 56px;
    margin-bottom: 96px;
  `};
`;

export const CaseStudiesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletMin`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

export const CaseStudyItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  text-decoration: none;

  &:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.media.tabletMin`
    width: calc(50% - 12px);
    margin-top: 0;
    margin-left: 0;
    margin-right: 12px;

    &:nth-child(2n) {
      margin-left: 12px;
      margin-right: 0px;
    }

    &:nth-child(n + 3) {
      margin-top: 48px;
    }
  `};

  ${({ theme }) => theme.media.desktopMin`
    width: calc(50% - 24px);
    margin-left: 0px;
    margin-right: 24px;

    &:nth-child(2n) {
      margin-left: 24px;
      margin-right: 0px;
    }
  `};
`;

export const CaseStudyImage = styled(Img)`
  display: block;
  width: 100%;
  height: auto;
  background-color: #eee;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(0.95);
  }
`;

export const CaseStudyImageEmpty = styled.img`
  display: block;
  width: 100%;
  padding-top: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.04);

  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &:hover {
    transform: scale(0.95);
  }
`;

export const CaseStudyTitle = styled(Typo.H3)`
  margin-top: 37px;
  text-align: center;
`;

export const CaseStudyDescription = styled(Typo.BodyRegular)`
  margin-top: 4px;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.values.grey};

  ${({ theme }) => theme.media.tabletLandscapeMin`
      margin-top: 8px;
  `};
`;
