import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import * as PageComponent from "../../components/pageComponents/caseStudies";

import MainLayout from "../../components/layout/main";

// MARK: Render

const renderCaseStudies = (thumbnailImages: any[], contents: any[]) => {
  return contents
    .map(content => {
      // get thumbnail image
      const thumbnailImage = thumbnailImages.find(image => {
        return image.relativeDirectory.includes(content.frontmatter.slug);
      });
      if (!thumbnailImage) {
        // tslint:disable-next-line:no-console
        console.error(`Thumbnail missing for ${content.frontmatter.slug}`);
      }
      return {
        title: content.frontmatter.title,
        description: content.frontmatter.thumbnail.description,
        slug: content.frontmatter.slug,
        childImageSharp: thumbnailImage.childImageSharp
      };
    })
    .map(thumbnail => {
      return (
        <PageComponent.CaseStudyItem
          key={thumbnail.slug}
          to={`/case-studies/${thumbnail.slug}`}
        >
          <PageComponent.CaseStudyImage
            fluid={thumbnail.childImageSharp.fluid}
            title={thumbnail.title}
            alt={thumbnail.title}
          />
          <PageComponent.CaseStudyTitle children={thumbnail.title} />
          <PageComponent.CaseStudyDescription
            children={thumbnail.description}
          />
        </PageComponent.CaseStudyItem>
      );
    });
};

interface CaseStudiesPageProps {
  data: {
    caseStudyThumbnails: any;
    caseStudyContents: any;
  };
}

const CaseStudiesPage: React.FC<CaseStudiesPageProps> = ({ data }) => {
  const caseStudyThumbnails = data.caseStudyThumbnails.edges.map(
    (edge: any) => edge.node
  );
  const caseStudyContents = data.caseStudyContents.edges.map(
    (edge: any) => edge.node
  );

  return (
    <MainLayout>
      {
        // MARK: –– Head
      }
      <Helmet>
        <title>Oroboro | Case Studies</title>
      </Helmet>

      {
        // MARK : –– Case studies section
      }
      <PageComponent.CaseStudiesSection id="case-studies">
        <PageComponent.CaseStudiesContainer
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {renderCaseStudies(caseStudyThumbnails, caseStudyContents)}
        </PageComponent.CaseStudiesContainer>
      </PageComponent.CaseStudiesSection>
    </MainLayout>
  );
};

export default CaseStudiesPage;

// MARK: Page query

export const pageQuery = graphql`
  query {
    # case studies
    caseStudyThumbnails: allFile(
      filter: { dir: { regex: "/content/*/" }, name: { eq: "thumbnail" } }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            fluid(maxHeight: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    caseStudyContents: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            thumbnail {
              description
            }
          }
        }
      }
    }
  }
`;
